:root {
    --color-background: #F0F0F7;
    --color-primary-lighter: rgb(7, 67, 85);
    --color-primary-light: rgb(7, 67, 85);
    --color-primary: #FFF;
    --color-primary-dark: #666666;
    --color-primary-darker: #666666;
    --color-secundary: #04D361;
    --color-secundary-dark: #04BF58;
    --color-title-in-primary: #FFFFFF;
    --color-text-in-primary: #D4C2FF;
    --color-text-title: #32264D;
    --color-text-complement: #9C98A6;
    --color-text-base: #000000;
    --color-line-in-white: #E6E6F0;
    --color-input-background: #F8F8FC;
    --color-button-text: #FFFFFF;
    --color-box-base: #ffffff;
    --color-box-footer: #FAFAFC;
  
    font-size: 60%;
  }

* {
margin: 0;
padding: 0;
box-sizing: border-box;
}

html, body, #root {
    min-height: 100vh;
}

body {
    background-color: var(--color-background);
}

#root {
    display: flex;
    align-items: center;
    justify-content: center;
}
.touch #root{
    align-items: stretch;
}

.container {
    width: 90vw;
    max-width: 700px;
}

@media(min-width: 700px){
    :root {
        font-size: 62.5%;
    }
}