body {
  font: 500 1.6rem 'Roboto', 'Helvetica', 'Arial', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #19222a !important;
  margin: 0;
}

/*Font**/

@font-face {
  font-family: 'MERCOSUL';
  src: url('./fonts/FE-FONT.ttf') format('truetype');
}

.licensePlates .licensePlate .license,
.scheduling-exp.painel-page
  .MuiDataGrid-root
  .MuiDataGrid-cell.MuiDataGrid-cell--textLeft:nth-of-type(2),
.app.mobile
  .home-expedition
  .MuiDataGrid-root
  .MuiDataGrid-cell.MuiDataGrid-cell--textLeft:nth-of-type(2),
.app.mobile
  .home-expedition
  .MuiDataGrid-root
  .MuiDataGrid-cell.MuiDataGrid-cellLeft:nth-of-type(2) {
  font-family: MERCOSUL !important;
}

body,
input,
button,
textarea {
  font: 500 1.6rem 'Roboto', 'Helvetica', 'Arial', sans-serif;
  color: var(--color-text-base);
}

$green: green;
$red: red;
$red-1: #ee4d64;
$red-2: #de3b3b;
$blue-2: #4d85ee;
$main-logo: #fc8f00;
$bg-dark: #19222a;
$bg-dark: lighten(#00507f, 0%);
$bg-dark: #1d1d1b;
$gray-fa: #fafafa;
$gray-cold: #f8f9ff;

$space-1: 50px;
$space-2: 40px;
$space-3: 20px;

@mixin legend {
  color: #444;
  font-size: 24px;
}

@mixin box {
  color: #444;
  margin-top: $space-3;
  padding: 5px 20px 20px;
  border: 0;
  box-shadow: #0000001f 2px 2px 13px 1px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app {
  background-color: #fff;
  display: flex;

  .col1,
  .col2 {
    transition: 0.3s ease-out;
  }
}

html {
  --bg-dark: #19222a;
}

.MuiAppBar-colorPrimary {
  background-color: #323b8d !important;
  background-color: $bg-dark !important;
  margin-bottom: 10px;
}

// COMPONENTES GERAIS
header {
  #btBack {
    background: #ccc;
    color: #666;

    &:hover {
      background: darken(#ccc, 10%);
      color: darken(#666, 10%);
    }
  }

  #btSave {
    background: $green;

    &:hover {
      background: lighten($green, 10%);
      color: lighten(white, 10%);
    }
  }

  button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 0;
    background: #fc8f00;
    min-width: 150px;
    height: 36px;
    color: #fff;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 700;
    right: 0;
    padding: 0px 15px;
    width: fit-content;

    svg {
      display: none;
    }

    &:hover {
      background: lighten($main-logo, 10%);
      color: lighten(white, 10%);
    }
  }
}

.settings-delay-content {
  .row {
    .input-block {
      width: 100%;
    }
  }
}

.makeStyles-tabRoot-3 {
  width: 100%;
  height: fit-content !important;

  div#vertical-tabpanel-1 {
    flex-grow: 1;
  }
}

.myModal {
  header {
    display: flex;
    flex-direction: column;
  }
}

input::placeholder {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 16.5px;
  font-weight: 500;
}

// ABA EXTERNA
.joint-list,
.userList,
.companyList,
.customerList,
.driverList,
.orderList,
.productList,
.shippingCompanyList,
.truckList {
  .content {
    width: calc(100vw - 15em);
    width: 100%;
    margin: 0 auto;

    table thead tr th:first-child :hover {
      cursor: pointer;
    }
  }

  td {
    padding-left: $space-3;

    &:nth-of-type(even) {
      background-color: #ececec;
    }
  }

  th {
    text-align: left;
    font-size: 16px;
    color: #fff;
    padding-left: $space-3;
    font-weight: 700;
    width: 300px;
    background-color: #666;
  }
}

.log-details,
.order-details {
  display: grid;
  gap: 30px;
  background: white;

  > div:not(.order-content) {
    display: grid;
    grid-template-columns: 15% 1fr;
    /* margin-bottom: 30px; */
  }
}

.companyList .content table {
  .actionButton {
    background: 0 0;
    border: 0.5px gray solid;
    border-radius: 4px;
    font-size: 15px;
    margin-left: 10px;
    width: 70px;
    height: 30px;
  }

  thead tr th:first-child :hover {
    cursor: pointer;
  }
}

.app {
  ._container {
    display: flex;
    flex-flow: row nowrap;
    height: cal(100vh (-50px));
    width: 100vw;

    #logo {
      line-height: 1;
      width: 150px;
    }

    .col1 {
      width: 12em;
      position: static;
      height: cal(100vh (-50px));
      background-color: $bg-dark;
    }
  }

  .sidebar-content {
    header img {
      width: 11em;
      margin-left: 5px;
    }

    &.active {
      #sys {
        display: none;
      }

      &.col1 {
        header {
          img {
            display: none;
          }

          width: 65px;
        }

        width: 65px;
        margin: 0 auto;
      }
    }
  }

  .col2 {
    width: calc(100vw - 12em);
    height: 100vh;
    position: relative;

    .topbar-content {
      background-color: $bg-dark;
      height: $space-1;
      position: absolute;
      width: 100%;
      padding: 5px;
      padding: 0 $space-3;
      display: flex;
      gap: 30px;
      align-items: center;
      justify-content: space-between;

      .avatar {
        display: flex;
        gap: 5px;
        justify-content: center;

        > div {
          display: flex;
          flex-flow: column;
          line-height: 1.2;
        }

        align-items: center;

        .MuiAvatar-root {
          height: 35px;
          width: 35px;
        }

        strong {
          color: #ffffffdd;
          font-weight: 400;
        }
      }
    }

    #content {
      margin-top: calc(10px + 36px + 30px);
      height: calc(100vh - (50px + 36px + 30px));
      width: 100%;
      overflow-y: scroll;
      padding: $space-3;

      .ext-grid + & {
        margin-top: 50px !important;
        height: calc(100vh - 50px) !important;
      }
    }
  }
}

.login {
  width: 80%;

  .container .logo {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 500px) {
    width: -webkit-fill-available;
    margin: 20px;
  }

  border-radius: 4px;

  .container {
    display: flex;
    flex-direction: row;

    @media (max-width: 500px) {
      flex-direction: column-reverse;
      gap: 30px;
      padding: 5%;
    }

    align-items: center;
    min-width: 100%;
    width: 100%;
    box-shadow: 1px 1px 5px #444;
    padding: $space-1;
    background: white;

    .logo {
      flex-direction: column;
      margin: 0 auto;

      img {
        width: 640px;
        height: auto;

        @media (max-width: 500px) {
          width: 150px;
          margin: 10px;
        }
      }
    }
  }

  .login-box {
    width: 100%;
    max-width: 360px;
    height: 448px;
    text-align: center;
    color: #3c3c3bff !important;
    background: #8859ff17;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 0 $space-2;

    input {
      background: 0 0;
      border: 0;
      border-bottom: 1px solid #3c3c3bff !important;
      height: 45px;
      color: #3c3c3bff;
      font-size: 16px;
      border-color: #ddd;
      margin: 0 0 10px;
      padding: 0 15px;

      &:focus {
        outline: 0;
        border-bottom: 1px solid $main-logo !important;
      }
    }

    form {
      display: flex;
      flex-direction: column;
      margin-top: $space-2;
    }

    h1 {
      margin-top: $space-3;
    }
  }

  label {
    align-self: flex-start;
    font-weight: 700;
    font-size: 14px;
    margin-bottom: 5px;
    color: #444;
  }

  span {
    //color: #fb6f91;
    align-self: flex-start;
    font-weight: 700;
    margin: 0 0 10px;
  }

  #button-login {
    height: 45px;
    background: $main-logo;
    font-weight: 700;
    color: #fff !important;
    border: 0;
    border-radius: 4px;
    font-size: 18px;
    transition: background 0.2s;
    margin: 50px 0 0;

    &:hover {
      background: lighten($main-logo, 10%) !important;
    }
  }

  .nova-conta {
    margin-top: 15px;

    a {
      color: #202020;
    }
  }

  a {
    margin-top: 15px;
    font-size: 18px;
    opacity: 0.8;

    &:hover {
      opacity: 1;
    }
  }
}

#page-landing {
  text-align: center;
  color: #000;
  background: var(--color-primary);
}

.logo-container {
  img {
    height: 10rem;
  }

  text-align: center;
  margin-bottom: 3.2rem;

  h2 {
    font-weight: 500;
    font-size: 2.4rem;
    line-height: 4.6rem;
    margin-top: 0.8rem;
  }
}

.buttons-container {
  display: flex;
  justify-content: center;
  margin: 3.2rem 0;

  a {
    width: 30rem;
    height: 10.4rem;
    border-radius: 0.8rem;
    font: 700 2rem Arquivo;
    text-decoration: none;
    color: var(--color-button-text);
    transition: oopacity 0.3s;

    &:first-child {
      margin-right: 1.6rem;
    }

    img {
      width: 4rem;
      margin-right: 2.4rem;
    }

    &.study {
      background: var(--color-primary-lighter);
    }

    &.give-classes {
      background: var(--color-secundary);
    }

    &:hover {
      opacity: 0.7;
    }
  }
}

.total-connections {
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    margin-left: 0.8rem;
  }
}

.search,
.sidebar .sidebar-nav ul li a {
  display: flex;
  align-items: center;
}

.search span {
  line-height: 1;
}

a {
  text-decoration: none;
  -webkit-transition: all 0.6s ease;
  -moz-transition: all 0.6s ease;
  transition: all 0.6s ease;

  &:hover {
    -webkit-transition: all 0.6s ease;
    -moz-transition: all 0.6s ease;
    transition: all 0.6s ease;
  }
}

.sidebar,
.sidebar-nav {
  background-color: $bg-dark;
  overflow: hidden;
}

.sidebar {
  position: fixed;
  color: #fff !important;
  width: 12em;
  min-height: 800px;
  -webkit-transform: translateZ(0);
  visibility: visible;
  -webkit-backface-visibility: hidden;
  transition-duration: 0.3s;
}

.app .sidebar-content.active .sidebar {
  transition-duration: 0.3s;
  width: 65px;

  &.toggle {
    transition-duration: 0.3s;
    width: 180px;
  }
}

.sidebar-nav {
  position: absolute;
  height: 100%;
  font-weight: 400;
  font-size: 1.2em;
  padding-bottom: 6em;
  z-index: 100;
  -webkit-overflow-scrolling: touch;

  ul {
    list-style: none;
    display: block;
    line-height: 1;
    margin: 0;
    padding: 0;

    li {
      margin-left: 0;
      padding-left: 0;
      display: inline-block;
      width: 100%;

      .icon {
        img {
          width: 30px;
        }

        svg {
          fill: transparent;
        }
      }

      span {
        width: 30px;
        text-align: right;
      }
    }
  }
}

.app .sidebar-content.active .icon {
  margin-left: 5px;
}

.sidebar-nav .arrow-icon {
  float: right;
  position: relative;
}

.sidebar .sidebar-nav ul li a {
  height: 60px;
  color: rgba(255, 255, 255, 0.9);
  font-size: 0.75em;
  text-decoration: none;
  gap: 5px;
  border-bottom: 1px solid;
  border-color: darken($bg-dark, 5%);
  padding: 17px;
}

.active .sidebar-nav ul li a {
  padding: 1.05em 0.8em;

  span {
    &:nth-child(2) {
      display: none;
      transition-duration: 0.8s;
    }

    &:nth-child(3) {
      margin-left: $space-3;
      margin-top: -25px;
      transition-duration: 0.8s;
    }
  }
}

.sidebar-nav {
  ul li {
    a:hover {
      background-color: darken($bg-dark, 10%);
      -webkit-transition: all 0.6s ease;
      -moz-transition: all 0.6s ease;
      transition: all 0.6s ease;
    }

    i {
      font-size: 1.8em;
      padding-right: 0.5em;
      width: 9em;
      display: inline;
      vertical-align: middle;
    }
  }

  > ul > li > a:after {
    font-family: ionicons;
    font-size: 0.5em;
    width: 10px;
    color: #fff;
    position: absolute;
    right: 0.75em;
    top: 45%;
  }
}

.MuiDataGrid-root {
  background: lighten($main-logo, 80%);
  background: white;
  background: $gray-cold;
  border: 0 !important;
  // box-shadow: #0000001f 1px 1px 4px 1px;
  font-size: 15px !important;

  .MuiDataGrid-cellWithRenderer,
  .MuiDataGrid-cell--withRenderer {
    gap: 5px;
  }

  .code {
    margin-bottom: 5px;
  }

  label {
    font-weight: bold;
    margin-right: 3px;
    margin-bottom: 3px;
  }

  .MuiDataGrid-main div:nth-child(2) {
    height: fit-content !important;

    .MuiDataGrid-virtualScroller {
      height: fit-content !important;
      overflow: hidden;

      .MuiDataGrid-virtualScrollerRenderZone {
        position: relative;
      }
    }
  }

  .schedule-icon,
  .warning-icon,
  .delete-icon,
  .edit-icon {
    background: $main-logo;
    color: white;
    fill: white;
    padding: 3.2px 4px;
    border-radius: 50%;
    line-height: 1;

    a {
      color: white;
    }
  }

  .delete-icon {
    background: $red-1;
  }

  .warning-icon {
    background: $red;
  }

  .schedule-icon {
    background: $green;
  }

  .MuiDataGrid-toolbar {
    padding: 0;
    background: #0000001c;

    .MuiButton-label {
      color: #202020;
    }
  }

  .MuiDataGrid-columnsContainer,
  .MuiDataGrid-columnHeaders {
    background: #0000000d;
    border-bottom: 0 !important;
  }

  .MuiDataGrid-row:nth-child(even) {
    background: #ececec;
  }

  .MuiDataGrid-toolbar {
    // background: black;
  }

  .MuiDataGrid-cell {
    border-bottom: 0 !important;
  }

  .MuiDataGrid-colCellWrapper {
    // background: $gray-fa;
  }

  .actionButton {
    margin-right: 10px !important;
  }
}

.actionButton {
  font-size: 15px !important;
  background: $blue-2;
  border: 0;
  color: #ffffff;
  font-weight: 500;
  padding: 5px;
  border-radius: 5px;
  font-size: inherit;
  font-family: inherit;
  width: 70px;
  line-height: 1;
  margin-right: 10px;

  &:last-child {
    margin-right: 0;
  }

  &.blue {
    background: $blue-2;
  }

  &.red {
    background: $red-2;
  }

  &.disabled {
    background-color: rgb(88, 88, 88);
  }
}

// .new  --- vinha junto no estilo de botão

// Aba extermas
.joint-list,
.userList,
.companyList,
.customerList,
.orderList,
.driverList,
.productList,
.truckList,
.shippingCompanyList {
  display: flex;
  flex-direction: column;
  // padding-top: $space-1;
  width: 100%;
  max-width: 1200px;
  max-width: 80vw;
  margin: auto;

  header {
    display: flex;
    flex-direction: column;

    strong {
      color: $main-logo;
    }
  }

  .search-header {
    margin-top: $space-3;
    margin-bottom: $space-3;
    display: flex;
    justify-content: space-between;
  }

  .search input {
    height: 36px;
    width: 237px;
    color: #444;
    font-size: 16px;
    padding: 0 10px;
    border: 0;
    background: $gray-fa;
    margin-right: 6px;
  }

  .search svg {
    fill: #666666;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    border-style: hidden;
  }

  tr {
    height: $space-2;
    font-size: 16px;
    color: #666;
    border-bottom: 1px solid #eee;
  }

  strong {
    color: #444;
    font-size: 24px;
    width: 100%;
  }
}

.app {
  .sidebar-content header {
    justify-content: center;
    align-items: center;
    display: flex;
    color: #fff;
    width: 12em;
    height: $space-1;
    background-color: $bg-dark;
    justify-content: left;
    padding: $space-3;
  }

  .col2.active {
    width: calc(100% - 65px);
  }
}

.hero-image {
  width: 100%;
}

@media (min-width: 1100px) {
  .logo-container {
    grid-area: logo;
    align-self: center;
    text-align: left;
    margin: 0;

    h2 {
      text-align: initial;
      font-size: 3.6rem;
    }

    img {
      height: 100%;
    }
  }

  .hero-image {
    grid-area: hero;
    justify-self: end;
  }

  .buttons-container {
    grid-area: buttons;
    justify-content: flex-start;

    a {
      font-size: 2.4em;
    }
  }

  .total-connections {
    grid-area: total;
    justify-self: end;
  }
}

/* INTERRNAS */

.switch {
  margin-top: 10px;
  position: relative;
  display: inline-block;
  width: 90px;
  height: 34px;

  &.disabled {
    .slider {
      cursor: not-allowed;
      opacity: 0.6;
    }
  }
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ca2222;
  -webkit-transition: 0.4s;
  transition: 0.4s;

  &:before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: #fff;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
}

input {
  &:checked + .slider {
    background-color: #2ab934;
  }

  &:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  &:checked + .slider:before {
    -webkit-transform: translateX(55px);
    -ms-transform: translateX(55px);
    transform: translateX(55px);
  }
}

.on,
.off {
  color: #fff;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  font-size: 10px;
  font-family: Verdana, sans-serif;
}

input:checked + .slider .on {
  display: block;
}

.slider.round {
  border-radius: 34px;

  &:before {
    border-radius: 50%;
  }
}

.validateError {
  color: $red;
  margin-left: 10px;
}

.new-order-container {
  .row {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
  }

  .select-options {
    margin-top: 0.8rem !important;

    div:first-child {
      min-height: 35px !important;
    }
  }

  td {
    padding-left: 10px;
    padding-bottom: 10px;
    padding-top: 5px;
  }

  .select-customer,
  .select-shipping,
  .select-product {
    width: 500px;
    height: $space-1;
  }

  .qnt {
    width: 130px;
    margin-left: $space-3;
  }
}

#addDelay,
#addProduct {
  width: 130px;
  height: 39px;
  margin-top: 30px;
  // margin-left: 10px;
  background: #2ab934;
  color: white;
  border-radius: 5px;
  border: 0;
}

#addDelay {
  margin-top: 10px;
  margin-bottom: 10px;
}

span.MuiTab-wrapper {
  font-size: 14px;
  font-weight: bold;
  text-transform: capitalize;
  align-items: flex-end;
  margin-right: 15px;
  display: flex;
  flex-direction: row-reverse;
  gap: 6px;
  align-items: center;

  > *:first-child {
    margin-bottom: 0 !important;
  }

  svg {
    fill: #555555;
  }
}

.new-truck-container {
  table #addContact {
    width: 120px;
  }

  .row .input-number {
    margin-right: 6em;
    width: 150px;
  }

  .contacts {
    padding-bottom: $space-3;
  }
}

// .schedulings --> vinha junto com a interna na parte de tabela

// internas

.new-product-container,
.new-scheduling-container,
.new-user-container,
.new-company-container,
.new-driver-container,
.new-order-container,
.new-truck-container,
.settings-container,
.dashboard-container {
  header {
    position: absolute;
    top: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 15px 20px;
    background: #ffffff0d;
    backdrop-filter: blur(1.5px);
    width: 100%;
    left: 0;
    z-index: 1000;
    justify-content: space-between;
    box-shadow: #00000017 0px 1px 3px 0px;
  }
}

.user-aprove-term,
.new-product-container,
.new-scheduling-container,
.new-user-container,
.new-company-container,
.new-driver-container,
.new-order-container,
.new-truck-container,
.settings-container,
.dashboard-container {
  max-width: 1100px;
  display: flex;
  flex-direction: column;
  max-width: 80vw;
  margin: auto;
  padding-bottom: 70px;

  strong {
    color: $main-logo;
    font-size: 24px;
  }

  .buttons-header {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    margin-top: $space-3;
    margin-bottom: $space-3;

    .uf {
      width: 100px;
    }
  }

  label {
    font-size: 14px;
    font-weight: 700;
    color: #444;
  }

  .row {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    gap: $space-3;

    .input {
      width: 50%;
    }
  }

  fieldset {
    color: #444;
    margin-top: $space-3;
    border: 0;

    tr {
      padding-bottom: 10px;
    }

    legend {
      @include legend;
    }

    input {
      width: 100%;
      height: $space-2;
    }

    th {
      background: #0000000d;
      padding: 0 15px;
      color: #202020;
      font-weight: normal;
      vertical-align: middle;
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;
    border-style: hidden;
  }

  th {
    text-align: left;
    font-size: 16px;
    color: #fff;
    font-weight: 500;
    width: 300px;
  }

  tr {
    height: $space-2;
    font-size: 16px;
    color: #666;
    border-bottom: 1px solid #eee;
  }

  table .input-block input {
    background: white;
  }

  table button {
    background: white;
    background: $main-logo;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 15px;
    width: 70px;
    height: 40px;
  }

  td {
    padding-left: 5px;
  }

  table #addContact {
    width: 120px;
  }
}

.user-aprove-term label {
  font-size: 12px;
}

.switch input,
.on,
input:checked + .slider .off {
  display: none;
}

.input.week-days {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 0 10px;
  align-items: center;

  > div {
    grid-column: span 2;
  }
}

.new-scheduling-container td:last-of-type {
  display: flex;
}

.new-order-container tbody tr td,
.contacts tr td {
  height: 60px;
}

.contact input,
.new-order-container th input,
.contacts th input {
  margin-top: 0 !important;
}

.contacts tbody {
  padding: 0 15px;
}

.accordion-session {
  th {
    padding: 0 15px;
    color: #202020;
    font-weight: normal;
    vertical-align: middle;
  }
}

fieldset {
  position: relative;
  margin-top: 20px + 40px !important;

  legend {
    display: block;
    position: absolute;
    padding: 0;
    top: -40px;
  }

  background: #f8f9ff;
}

.schedulings > fieldset,
#new-schedule {
  .schedule-header {
    h2 {
      font-family: 'Segoe UI', Arial, sans-serif;
      font-weight: 400;
      margin: 25px 0;
    }

    color: #fff !important;
    background-color: #32a457 !important;
    padding: 0.01em 16px;
  }

  .schedule-content {
    label {
      font-weight: bolder;
    }

    .select-options {
      margin-top: 5px;
    }

    padding: 0.01em 16px 25px;
  }

  // margin-top:80px!important;
  box-shadow: 0 0 3px 0 rgb(0 0 0 / 20%), 0 0 3px 0 rgb(0 0 0 / 19%);
}

.new-scheduling-container,
.new-order-container {
  .input-block {
    width: 100%;
  }

  .select-options > div {
    *:first-child {
      min-height: initial !important;
    }

    border-radius: 0;
    margin-top: 0.8rem;
    background: var(--color-input-background);
    border: 0;
    outline: 0;
    font-family: Poppins;

    span[class$='indicatorSeparator'] {
      display: none;
    }
  }
}

.input-block {
  select,
  input {
    width: 100%;
    height: $space-2;
    margin-top: 5px;
    background: var(--color-input-background);
    border: 0;
    outline: 0;
    padding: 0 1.6rem;
    font-family: Poppins;
    font-size: 14px;

    &::placeholder {
      font-size: 14px;
      font-weight: 500;
    }
  }

  .error & input {
    border-left: 5px solid red;
    background-color: rgb(255, 188, 188) !important;

    &::placeholder {
      color: red;
    }
  }

  &:focus-within::after {
    width: 100%;
    height: 2px;
    content: '';
    background: var(--color-primary-light);
    position: absolute;
    left: 0;
    right: 1.6rem;
    bottom: 0;
  }
}

input {
  &:checked + .slider .on {
    right: -10px;
  }

  + .slider .off {
    right: -10px;
    left: initial;
  }
}

.row.flexpertinho {
  justify-content: start;
}

.timmer-menu {
  display: grid;
  grid-template-columns: 1fr 1fr;
  color: #999999;
  margin-bottom: 13px;

  .timmer-column {
    display: grid;
  }

  .suspend.suspend-active {
    color: #202020;
    border-color: #999999;
  }

  & > div {
    display: flex;
    gap: 11px;
    justify-content: center;
    /* border-right: solid 1px; */
    padding: 10px 0;
    background: #fafafa;
    border-color: #f0f0f0;

    &.delay,
    &.tolerance-exped {
      border-right: solid 1px darken(#fafafa, 10%);
      align-items: center;
    }
  }
}

.box {
  display: inline-block;
  padding: 10px;
  box-sizing: border-box;
}

.img-preview {
  overflow: hidden;
}

.powerBy {
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.Toastify svg {
  opacity: 1;
  color: #fff;
  fill: #fff;
  display: block;
  width: 16px;
}

.Toastify button {
  background: none !important;
  width: 30px;
  /* height: 100%; */
  align-self: center;
}

.app.mobile .content .license {
  height: 65px;
  padding-top: 10px;
}

.scheduling-exp.painel-page
  .MuiDataGrid-root
  .MuiDataGrid-cell.MuiDataGrid-cellLeft:nth-of-type(2)
  > div,
.app.mobile
  .home-expedition
  .MuiDataGrid-root
  .MuiDataGrid-cell.MuiDataGrid-cellLeft:nth-of-type(2)
  > div {
  height: 40px;
  padding: 0px;
  padding-bottom: 15px;
}

/////Settings

.row.wrap {
  flex-wrap: wrap;
}

.app.mobile .actionButton.red {
  border-color: #c01616 !important;
  color: #ffffff !important;
}
