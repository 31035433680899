.pub.page-layout .locationCompany{
    width: 100vw;
    height: 100vh;
}
.pub.page-layout .locationCompany{
    display: grid;
    grid-auto-rows: auto 1fr;
}
.pub.page-layout .locationCompany iframe{
    height: 100%;
    width: 100vw;
}
.pub.page-layout .locationCompany .MuiToolbar-root{
    justify-content: center;
}
.pub.page-layout .locationCompany .MuiToolbar-root button{
    display: none;
}
.pub.page-layout .locationCompany .MuiToolbar-root img{
    max-height: 80px;
}
.pub.page-layout .locationCompany .MuiToolbar-root div:last-child{
    display: none;
}
.pub.page-layout .locationCompany .MuiAppBar-root{
    position: relative;
    margin-bottom: 0;
}
.pub.page-layout .locationCompany iframe .place-card{
    width: 100vw;
    max-width: 400px;
}
.pub.page-layout .locationCompany iframe .place-card .navigate{
    display: inline-block !important;
}