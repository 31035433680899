    .virtual-queue .MuiPaper-root.MuiAppBar-root.MuiAppBar-positionStatic.MuiAppBar-colorPrimary.MuiPaper-elevation4{
        background:#323B8D!important;
        .MuiToolbar-root>button{
            visibility: hidden;
        }
    }

    .virtual-queue {
   
        .confirm-schedule,
        .confirm-suspend {
            position: fixed;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            /* transform: translateY(-50%); */
            background: #00000088;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 100;
            color: black;

            label {
                font-size: 18px;
            }

            .buttons {
                margin-top: 10px;
            }

            >div {
                background: white;
                padding: 15px;
                /* border-radius: 10px; */
                min-width: 310px;
                text-align: center;
            }
        }


        width:100%;

        .container {
            width: 100%;
        }

        .content {
            display: flex;
            flex-flow: column;
            gap: 20px;

            width: 90vw;
            max-width: 700px;
            margin: auto;
            height: auto;
        }

        .today {
            font-size: 20px;
            color: #6d6d6d;
            margin-top: 15px !important;
            margin-bottom: 10px;
            margin-left: auto;
            text-align: center !important;
            
        }

        .service-status {
            display: flex;
            justify-content: space-between;
            color: gray;
            margin-bottom: 15px;

            .column {
                flex-direction: column;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 60px;
                flex-grow: 1;

            }

            .column div {
                border: solid 1px;
                border-color: #cccc;
                border:none;
                background:#f0f0f0;
                color:#333;
                border-radius: 5px;
                margin-bottom: 5px;
                width: 80px;
                height: 50px;
                font-size: 30px;
                text-align: center;
                display:flex;
                justify-content: center;
                align-items: center;
                &.green{
                    background: #32A457;
                    color: #fff!important;
                }
                &.red{
                    background: #fc4040;
                    color: #fff!important;
                }
            }

            .column span {
                font-size: 15px;
            }

            .column strong {
                font-size: 15px;
            }
        }

        .service-data {
            margin-bottom: 25px;
            margin-bottom: 25px;
            border: solid #ccc 1px !important;
            border-radius: 5px;
            overflow: hidden;
            color: #ccc;
            position: relative;
            height: 40px !important;

            * {
                position: absolute !important;
                height: 40px !important;
                top: 0 !important;
            }

            >span {
                right: 15px !important;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 20px;
            }
        }

        .atendimento {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-columns: 100px 2fr 1fr;
            padding: 7px;
            gap: 7px;
            align-items: center;
            border: solid 3px #32A457;
            border-radius: 5px;
            margin-bottom: 40px;

            strong {
                font-size: 20px;
            }

            >div:nth-of-type(1) {
                text-align: right;
                color: #32A457
            }

            >.licensePlate {
                letter-spacing: -1px;
                text-align: center;
                border: solid 3px #DE2A1C;
                text-align: center;
                font-weight: bolder;
                border-radius: 5px;
                margin-bottom: 0;

                .license {
                    font-weight: bolder;
                    font-size: 20px;
                    color: #DE2A1C;
                    letter-spacing: 0;
                    font-family: Oswald;
                    line-height: 1;
                    font-size: 28px;
                    padding: 10px 0;

                    @media(max-width:360px) {
                        font-size: 28px;
                    }

                    @media(min-width:370px) {
                        font-size: 30px;
                    }

                }
            }

            >div:nth-of-type(3) {
                background: linear-gradient(180deg, #C9C8BC 0%, rgba(255, 255, 255, 0) 100%), #E4E3D6;
                box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
                border-radius: 5px;
                height: 100%;
                min-width: 60px;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            >button {
                grid-column: 1/4;
                background: #32A457;
                border: 0;
                color: white;
                font-size: 20px;
                padding: 13px;
                transform: translateY(30px);
                margin-top: -30px;
                border-radius: 4px;
            }
        }

        .MuiDataGrid-iconSeparator {
            display: none
        }

        .MuiDataGrid-columnsContainer,
        .MuiDataGrid-window {
            display: block;
        }

        .MuiDataGrid-root {
            box-shadow: none;

            .MuiDataGrid-row {
                margin-bottom: 0px;
                margin-top: 10px;
            }

            .MuiDataGrid-cell--textLeft {
                text-align: left;
                padding: 5px;
                min-height: auto !important;
                max-height: none !important;
                line-height: 1 !important;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .MuiDataGrid-cell.MuiDataGrid-cell--textLeft:nth-of-type(2) {
            flex-direction: column;

                font-size: 30px;
                font-weight: bolder;
                min-width: auto !important;
                max-width: none !important;
                border-radius: 5px;
                color: red;
                font-family: Oswald;
                border-bottom: 3px solid !important;
                border: solid 3px;
                padding: 5px;
                line-height: 1 !important;
            }
        }

        .MuiDataGrid-colCell,
        .MuiDataGrid-colCellWrapper,
        .MuiDataGrid-columnHeaders {
            background: #323B8D;
            color: white;
            font-size: 15px;
        }

        .log-details,
        .order-details {
            .close {
                font-family: montserrat;
            }

            .previsao,
            .vehicleModel,
            .customer-info,
            .driver-info {
                margin: 15px 0;
                display: grid;
                grid-template-columns: auto 1fr;
                gap: 3px 10px;

                div {
                    max-width: 220px;
                }
            }
        }

        .MuiDataGrid-root .MuiDataGrid-footer {
            display: none;
        }

      .MuiDataGrid-main{
            display: flex;
            position: relative;
            flex-grow: 1;
            flex-direction: column;
        }


    }
