.popup {
  animation: disappear 0.3s ease-in-out;
}

@keyframes appear {
  from {
    opacity: 0;
    visibility: hidden;
  }

  to {
    opacity: 1;
    visibility: visible;
  }
}

.popup-overlay {
  position: absolute;
  inset: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(5px);

  background: rgba(255, 255, 255, 0.8);
}

.popup-container {
  position: relative;

  max-width: 240px;
  // min-height: 330px;
  width: 100%;
  background: #fff;
  border: 1px solid #000;
}

.popup-duration {
  padding: 4px 8px;
  margin-bottom: 24px;
  background: #adadad;
  border-radius: 9999px;
  text-align: center;

  span {
    font-size: 10px !important;
    color: #000;
  }
}

.popup-content {
  background: #fff;
}

.popup-header {
  background: #1b1b1a;
  padding: 8px;

  span {
    font-size: 20px;
    color: #fff;
  }
}

.popup-body {
  padding: 8px;
  background: #fff;
}

.popup-close-button {
  position: absolute;
  top: -18px;
  right: -10px;

  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fc8f00;

  cursor: pointer;
  border-radius: 9999px;
}
