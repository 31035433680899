.info-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.button-back {
  height: 50px;
  background: #999999;
  border: none;
  color: #fff;
  padding: 10px 25px;
  border-radius: 5px;
  min-width: 160px;
  font-size: 20px;
  cursor: pointer;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-text.erase-button {
  background: #adadad;
  border: none;
  color: #fff;
  padding: 7px 0px;
  border-radius: 5px;
  font-size: 12px;
  width: fit-content;
  margin-left: 15px;
}

.attendence-area .select-options {
  width: 100%;
}

select.select-options {
  align-items: center;
  min-width: 250px;
  border: none;
  height: 40px;
  background: #f8f8fc;
  outline: none !important;
}

select.select-options:focus-visible {
  outline: none;
}

.log .input.select {
  width: 25% !important;
}

.infor-container .input.select .select {
  margin-top: 5px;
}

.dashboard-page .infor-container {
  padding: 0px;
}

.suspend-loading {
  height: 100vh;
  display: flex;
}

.suspend-loading {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: calc(100% - 12em);
  left: 12em;
  outline: none;
}
.suspend-loading .container {
  background: transparent !important;
  max-width: 100vh;
  margin: 0px !important;
  height: fit-content !important;
}

.blur-1 {
  filter: blur(10px) !important;
}

.suspend-loading .container .loading {
  height: 120px;
}

.loading img {
  height: 100%;
}

.modal-instance > div:first-child {
  background-color: rgba(255, 255, 255, 0.5) !important;
  width: calc(100% - 12em);
  height: calc(100% - 50px);
  margin-left: auto;
  margin-top: auto;
}

.historic-list.productList header > div {
  color: #fc8f00;
  font-weight: 700;
}
