.tv-container {
  --background: #fc8f00;
  --yellow: #ffdf6c;
  --gray: #666666;

  --white: #fff;
  --success-light: #a6ec99;
  --success-dark: #296218;
  --success-medium: #bffd5b;
  --radius: 20px;
  --radius-full: 9999px;

  width: 100vw;
  height: 100vh;
  background: var(--background);
  display: flex;
  place-items: center;
}

.tv-container .content {
  max-width: 1000px;
  width: 100%;
  padding-inline: 20px;
  padding-top: 60px;
  margin: 0 auto;
}

.next {
  display: flex;
  gap: 24px;
}

.next .images {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 32px;
}

.next .images img {
  width: 100px;
}

.next .driver {
  padding: 12px 6px 6px;
  width: 100%;
  background: var(--yellow);
  border-radius: var(--radius);

  display: flex;
}

.driver p {
  font-size: 40px;
  color: var(--background);
  font-weight: normal;
  text-align: center;

  writing-mode: vertical-rl;
  transform: rotate(180deg);
}

.driver .details {
  background: var(--white);
  border-radius: var(--radius);
  flex: 1;
  padding: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.details time {
  padding: 4px 8px;
  display: inline-block;
  background: var(--background);
  color: var(--white);
  border-radius: var(--radius-full);
}

.details .name {
  font-size: 32px;
  font-weight: normal;
}

.next .truck-license-plate {
  font-size: 80px;
  color: #de2a1c;
  padding: 8px 12px;
  display: flex;
  justify-content: center;

  font-family: 'Mercosul';
  font-weight: bold;
  border: 3px solid;
  border-radius: 7px;
}

.summary {
  margin-top: 40px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 24px;
}

.summary .grid {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(5, 1fr);
  column-gap: 10px;
  row-gap: 24px;
}

.summary .attended {
  padding: 12px;
  background: var(--white);
  border-radius: var(--radius);
  text-align: center;

  display: flex;
  /* flex: 1; */
  height: 224px;
  flex-basis: 300px;
  flex-direction: column;
  justify-content: center;
}

.attended p {
  font-size: 100px;
}

.driver-card {
  height: 100px;
  background: var(--white);

  border-radius: var(--radius);
  padding: 20px 18px 4px;
  text-align: center;

  position: relative;
}

.driver-card p {
  font-size: 16px;
  margin-top: 8px;
}

.driver-card time {
  position: absolute;
  top: -14px;
  left: 4px;
  font-size: 12px;
  background: var(--gray);

  padding: 4px 8px;
  display: inline-block;
  color: var(--white);
  border-radius: var(--radius-full);
}

.driver-card .icon {
  width: 30px;
  height: 30px;
  padding: 4px;
  background: var(--success-medium);
  border-radius: var(--radius-full);

  position: absolute;
  top: -14px;
  right: 0;
  visibility: hidden;
  opacity: 0;
}

.driver-card.driver-card.in-attendance {
  background: var(--success-light);
}

.driver-card.in-attendance .icon {
  visibility: visible;
  opacity: 1;
}

.driver-card.in-attendance .driver-card.in-attendance time {
  background: var(--success-dark);
}
